.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fairy-ai-img, .face-swap-img, .face-dance-img, .thumb-img {
  max-width: 100px;
  padding-left: 20px;
}

.face-dance-video {
  max-width: 500px;
  padding-left: 20px;
}

.face-swap-video {
  max-width: 500px;
  padding-left: 20px;
}

.nav-item.active {
  font-size: larger;
}

.canvas-img {
  width: 100px;
  height: 100px;

}

.app-event-card {
  background-color: #e9efff !important;
  color: #000;
}
.screen-event-card {
  background-color: #a2d6a2 !important;
  color: #000;
}